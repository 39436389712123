exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-education-active-galaxy-eu-js": () => import("./../../../src/pages/education/activeGalaxyEU.js" /* webpackChunkName: "component---src-pages-education-active-galaxy-eu-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-education-supernova-eg-js": () => import("./../../../src/pages/education/supernovaEG.js" /* webpackChunkName: "component---src-pages-education-supernova-eg-js" */),
  "component---src-pages-education-tops-js": () => import("./../../../src/pages/education/tops.js" /* webpackChunkName: "component---src-pages-education-tops-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ppt-agn-ppt-js": () => import("./../../../src/pages/ppt/agnPPT.js" /* webpackChunkName: "component---src-pages-ppt-agn-ppt-js" */),
  "component---src-pages-ppt-bh-gravity-js": () => import("./../../../src/pages/ppt/bh_gravity.js" /* webpackChunkName: "component---src-pages-ppt-bh-gravity-js" */),
  "component---src-pages-ppt-bh-otherside-js": () => import("./../../../src/pages/ppt/bh_otherside.js" /* webpackChunkName: "component---src-pages-ppt-bh-otherside-js" */),
  "component---src-pages-ppt-bh-search-js": () => import("./../../../src/pages/ppt/bh_search.js" /* webpackChunkName: "component---src-pages-ppt-bh-search-js" */),
  "component---src-pages-ppt-bh-travel-js": () => import("./../../../src/pages/ppt/bh_travel.js" /* webpackChunkName: "component---src-pages-ppt-bh-travel-js" */),
  "component---src-pages-ppt-far-out-math-js": () => import("./../../../src/pages/ppt/farOutMath.js" /* webpackChunkName: "component---src-pages-ppt-far-out-math-js" */),
  "component---src-pages-ppt-pi-sky-js": () => import("./../../../src/pages/ppt/piSky.js" /* webpackChunkName: "component---src-pages-ppt-pi-sky-js" */),
  "component---src-pages-resources-active-galaxy-pop-up-js": () => import("./../../../src/pages/resources/activeGalaxyPopUp.js" /* webpackChunkName: "component---src-pages-resources-active-galaxy-pop-up-js" */),
  "component---src-pages-resources-black-hole-resource-area-js": () => import("./../../../src/pages/resources/blackHoleResourceArea.js" /* webpackChunkName: "component---src-pages-resources-black-hole-resource-area-js" */),
  "component---src-pages-resources-fermi-art-posters-js": () => import("./../../../src/pages/resources/fermiArtPosters.js" /* webpackChunkName: "component---src-pages-resources-fermi-art-posters-js" */),
  "component---src-pages-resources-fermi-glast-cube-js": () => import("./../../../src/pages/resources/fermiGlastCube.js" /* webpackChunkName: "component---src-pages-resources-fermi-glast-cube-js" */),
  "component---src-pages-resources-fermi-race-car-game-js": () => import("./../../../src/pages/resources/fermiRaceCarGame.js" /* webpackChunkName: "component---src-pages-resources-fermi-race-car-game-js" */),
  "component---src-pages-resources-field-trip-to-science-js": () => import("./../../../src/pages/resources/fieldTripToScience.js" /* webpackChunkName: "component---src-pages-resources-field-trip-to-science-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */)
}

